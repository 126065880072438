<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
  >
    <v-card tile>
      <v-card-title class="headline error white--text">
        Potwierdzenie czynności
      </v-card-title>

      <v-card-text class="mt-2">
        {{text}}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="close_dialog()"
        >
          Anuluj
        </v-btn>
        <v-btn
          color="primary"
          tile
          depressed
          @click="confirm_dialog()"
        >
          TAK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'Dialog',

    props: {
      // items: Array,
      text: String,
    },

    data: () => ({
      dialog: true
    }),

    methods: {
      close_dialog(){
        this.$emit('close');
      },
      confirm_dialog(){
        this.$emit('confirm');
      }
    }
  }
</script>

<style lang="scss">

</style>